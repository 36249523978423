import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSpring, useTrail, animated } from 'react-spring';

import { font, colors, margin, breakpoints } from 'src/modules/styles';
import { Logo } from 'src/modules/image';
import SermaidhSVG from 'src/images/svgs/sermaidhs.svg';
import TypografioSVG from 'src/images/svgs/typografio.svg';
import AthinaSVG from 'src/images/svgs/athina.svg';
import { Container, Background3D } from 'src/modules/layout';
import { useTransitionAnimation } from 'src/modules/animations';
import { SEO } from 'src/modules/seo';

const S = {
  Container: styled(Container)`
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
  `,
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: -40px;
  `,
  TextWrapper: styled(animated.div)`
    ${margin.bottom.s};
    mix-blend-mode: difference;
    height: 40px;

    ${breakpoints.s`
      height: 70px;
    `}
  `,
  Sermaidh: styled(SermaidhSVG)`
    mix-blend-mode: difference;
    height: 40px;

    ${breakpoints.s`
      height: 70px;
    `}

    path {
      fill: white;
    }
  `,
  Typografio: styled(TypografioSVG)`
    mix-blend-mode: difference;
    height: 40px;

    ${breakpoints.s`
      height: 70px;
    `}

    path {
      fill: white;
    }
  `,
  Athina: styled(AthinaSVG)`
    mix-blend-mode: difference;
    height: 40px;

    ${breakpoints.s`
      height: 70px;
    `}

    path {
      fill: white;
    }
  `,
  Logo: styled(Logo)`
    ${margin.bottom.s};
    mix-blend-mode: difference;

    path {
      fill: white;
    }
  `,
};

const A = {
  Logo: animated(S.Logo),
};

const textTransitionAnimations = [
  {
    status: ['entering', 'entered'],
    styles: {
      delay: 300,
      opacity: 1,
      x: 0,
      height: 45,
    },
  },
  {
    status: ['exiting', 'exited'],
    styles: {
      opacity: 0,
      x: 10,
      height: 0,
    },
  },
];

const logoTransitionAnimations = [
  {
    status: ['entering', 'entered'],
    styles: {
      delay: 300,
      opacity: 1,
    },
  },
  {
    status: ['exiting', 'exited'],
    styles: {
      opacity: 0,
    },
  },
];

const text = [S.Sermaidh, S.Typografio, S.Athina];

const HomePage = ({ transitionStatus }) => {
  const trail = useTrail(
    text.length,
    useTransitionAnimation(
      {
        config: { mass: 5, tension: 2000, friction: 200 },
        from: {
          opacity: 0,
          x: 10,
          height: 0,
        },
      },
      textTransitionAnimations,
      transitionStatus,
    ),
  );

  const logoAnimatedStyles = useSpring(
    useTransitionAnimation(
      {
        from: { opacity: 0 },
      },
      logoTransitionAnimations,
      transitionStatus,
    ),
  );

  const [animationsEnded, setAnimationsEnded] = useState(false);
  useEffect(() => {
    if (!['entering', 'entered', 'POP'].includes(transitionStatus)) {
      setAnimationsEnded(false);
    }
    const timeout = setTimeout(() => {
      setAnimationsEnded(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [transitionStatus]);

  return (
    <>
      {animationsEnded && <Background3D transitionStatus={transitionStatus} />}
      <S.Container>
        <SEO />
        <S.Wrapper>
          <A.Logo style={logoAnimatedStyles} />
          {trail.map(({ x, height, ...rest }, index) => {
            const Component = text[index];
            return (
              <S.TextWrapper
                key={text[index]}
                style={{ ...rest, transform: x.interpolate(x => `translate3d(0,${x}px,0)`) }}
              >
                <Component />
              </S.TextWrapper>
            );
          })}
        </S.Wrapper>
      </S.Container>
    </>
  );
};

HomePage.propTypes = {
  transitionStatus: PropTypes.string.isRequired,
};

export default HomePage;
